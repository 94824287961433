.order-block {
  width: 100%;
  min-height: 100vh;
  background-image: url('../assets/media/order/bg.png');
  background-position: left 123vw;
  background-repeat: no-repeat;
  background-size: 64.8vw 90.37vw;
}
.order-header-indent {
  width: 100%;
  height: 21.5vw;
}
.order-header {
  width: 100%;
  color: #BC9458;
  padding-left: 5.5vw;
  font-family: futura-pt-book;
  font-size: 22px;
}
.order-label {
  width: 100%;
  padding-top: 5vw;
  padding-left: 5.5vw;
  font-family: futura-pt-bold;
  font-size: 16px;
  color: #3D3933;
}
.order-info {
  width: 100%;
  padding-top: 2vw;
  padding-left: 5.5vw;
  font-family: futura-pt-book;
  font-size: 16px;
  color: #3D3933;
}
.order-data-block {
  margin-top: 1vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.order-input-block {
  margin-top: 5vw;
  width: 88.8%;
}
.order-input-label {
  color: #3D3933;
  font-family: futura-pt-book;
  font-size: 14px;
}
.order-input {
  margin-top: 1.4vw;
  color: #3D3933;
  display: block;
  width: 100%;
  height: 11.1vw;
  font-family: futura-pt-book;
  font-size: 16px;
  background-color: #FFFFFF;
  border: 1px solid #84848E;
  border-radius: 20px;
  padding: 0 5vw;
}
.order-input::-webkit-input-placeholder {
  color: #84848E;
  font-family: futura-pt-light;
 }
.order-button-block {
  width: 100%;
  height: 35vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-merch-block {
  width: 88.8%;
  padding: 2.77vw 0 2.77vw 2.77vw;
  background-color: #F9F6F2;
  border-radius: 15px;
  margin: 2.77vw auto;
  display: flex;
  flex-wrap: wrap;
}
.order-item {
  width: 16.2vw;
  height: 16.2vw;
  border-radius: 15px;
  background-color: #FFFFFF;
  margin: 2.77vw 2.77vw 2.77vw 0;
  background-position: center center;
  background-size: 90% 90%;
  background-repeat: no-repeat;
}
.order-tabbar-indent {
  width: 100%;
  height: 40vw;
}
.order-modal-block {
  position: fixed;
  background-color: rgba(61, 57, 51, .4);
  backdrop-filter: blur(5px);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-modal {
  width: 83.3%;
  min-height: 42.4vw;
  background-color: #3D3933;
  background-image: url('../assets/media/order/modal-bg.png');
  background-repeat: no-repeat;
  background-position: 66vw 5vw;
  background-size: 21.57vw 33.4vw;
  border-radius: 20px;
}
.order-modal-header {
  padding-top: 3.5vw;
  width: 100%;
  font-family: futura-pt-book;
  font-size: 24px;
  color: #FFFFFF;
  text-align: center;
}
.order-modal-text {
  padding: 2vw 3.5vw;
  width: 100%;
  font-family: futura-pt-book;
  font-size: 17px;
  color: #FFFFFF;
  text-align: center;
}
.order-modal-button {
  background-color: #BC9458;
  color: #FFFFFF;
  font-size: 16px;
  font-family: futura-pt-book;
  width: 36.57vw;
  height: 11.1vw;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 4vw auto;
}