.product-card {
  margin-bottom: 6.66vw;
  width: 43vw;
  height: 49.53vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-card-image {
  width: 16.66vw;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.product-card-info-block {
  position: relative;
  width: 25vw;
}
.product-favorit-icon {
  position: absolute;
  top: 0;
  right: 1vw;
  width: 3.7vw;
  height: 3.7vw;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.product-favorit-icon.disable {
  background-image: url('../assets/media/favorite-icon-disable.png');
}
.product-favorit-icon.enable {
  background-image: url('../assets/media/favorite-icon-enable.png');
}
.product-card-name {
  margin-top: 5.18vw;
  color: #BC9458;
  font-family: futura-pt-bold;
  font-size: 10px;
}
.product-card-descr {
  color: #3D3933;
  font-family: futura-pt-book;
  font-size: 11px;
  line-height: 14px;
}