.scan-result-block {
  position: relative;
  width: 100%;
  min-height: 100vh;
}
.scan-result-buttons-block {
  width: 100%;
  position: absolute;
  bottom: 8vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.scan-result-buttons-block > div {
  margin-top: 4vw;
}
.scan-result-header {
  padding-top: 14vw;
  text-align: center;
  color: #BC9458;
  font-family: futura-pt-bold;
  font-size: 22px;
}
.scan-result-text {
  padding-top: 20vw;
  margin: 0 auto;
  width: 88.8%;
  font-size: 18px;
  color: #3D3933;
  font-family: futura-pt-book;
  text-align: center;
}