.merch-block {
  width: 100%;
}
.merch-fav-icon {
  position: absolute;
  width: 6vw;
  height: 6vw;
  right: 4.33vw;
  top: 7.3vw;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  z-index: 1;
}
.merch-fav-icon.enable {
  background-image: url('../assets/media/merch/fav-icon-enable.png');
}
.merch-fav-icon.disable {
  background-image: url('../assets/media/merch/fav-icon-disable.png');
}
.merch-cover {
  width: 100%;
  height: 149.44vw;
  background-size: 100% auto;
  background-position: left top;
  background-repeat: no-repeat;
}
.merch-tag {
  position: absolute;
  left: 0;
  top: 17.5vw;
  padding: 0 6vw;
  height: 7.03vw;
  background-color: #BC9458;
  color: #FFFFFF;
  font-family: futura-pt-light;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 0 15px 15px 0;
}
.merch-content {
  width: 100%;
  margin-top: -6vw;
  background-color: #FFFFFF;
  border-radius: 30px 0 0 0;
}
.merch-name {
  padding: 6.48vw 5.55vw;
  font-family: futura-pt-book;
  color: #3D3933;
  font-size: 22px;
}
.merch-price {
  padding: 0 5.55vw;
  color: #BC9458;
  font-family: futura-pt-book;
  font-size: 22px;
}
.merch-price > span {
  text-decoration: line-through;
  color: #BCBCC1;
  font-family: futura-pt-light;
  font-size: 16px;
  padding: 0 3vw;
}
.merch-descr {
  font-family: futura-pt-book;
  font-size: 16px;
  color: #3D3933;
  padding: 6.48vw 5.55vw;
}
.merch-size-text {
  font-family: futura-pt-book;
  font-size: 16px;
  color: #3D3933;
  padding: 0 5.55vw;
}
.merch-size-buttons-block {
  margin: 3vw auto 12vw;
  width: 88.8%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.merch-size-button {
  width: 15.55vw;
  height: 7.4vw;
  font-family: futura-pt-light;
  font-size: 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.merch-size-button.enable {
  color: #FFFFFF;
  background-color: #BC9458;
}
.merch-size-button.disable {
  color: #BC9458;
  background-color: #F0E6D7;
}
.merch-cart-button {
  margin: 0 auto;
  height: 11.1vw;
  width: 88.8vw;
  color: #FFFFFF;
  font-size: 14px;
  font-family: futura-pt-book;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #BC9458;
}
.merch-cart-count-block {
  width: 88.8%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.merch-to-cart-button {
  height: 11.1vw;
  width: 49.6vw;
  color: #FFFFFF;
  font-size: 14px;
  font-family: futura-pt-book;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #BC9458;
}
.merch-count-block {
  width: 32.77vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.merch-count-minus {
  cursor: pointer;
  width: 11.1vw;
  height: 11.1vw;
  background-image: url('../assets/media/minus-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.merch-count-plus {
  cursor: pointer;
  width: 11.1vw;
  height: 11.1vw;
  background-image: url('../assets/media/plus-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.merch-count-text {
  font-size: 16px;
  font-family: futura-pt-book;
  color: #3D3933;
}
.merch-tabbar-indent {
  width: 100%;
  height: 40vw;
}