.scan-qr-scanning-block {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3D3933;
}
#scan-qr-reader {
  width: 100%;
}
.scan-qr-bg-block {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scan-qr-bg-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(50vh - 30vw);
  background-color: #3D3933;
}
.scan-qr-bg-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(50vh - 30vw);
  background-color: #3D3933;
}
.scan-qr-bg-left {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 20vw;
  background-color: #3D3933;
}
.scan-qr-bg-right {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 20vw;
  background-color: #3D3933;
}
.scan-qr-camera {
  width: 60vw;
  height: 60vw;
  background-image: url('../assets/media/scan-qr/qr-camera.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.scan-qr-descr-block {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #84848E;
  position: relative;
}
.scan-qr-descr-image {
  margin-top: 19.4vw;
  width: 92.6vw;
  height: 79.9vw;
  background-image: url('../assets/media/scan-qr/qr-descr.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.scan-qr-descr-button-block {
  margin-top: 6.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scan-qr-descr-button {
  background-color: #BC9458;
  color: #FFFFFF;
  font-size: 18px;
  font-family: futura-pt-book;
  width: 36vw;
  height: 12.9vw;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.scan-qr-permissions {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3D3933;
  color: #FFFFFF;
  font-size: 22px;
  text-align: center;
  font-family: futura-pt-book;
  padding: 0 40px;
}
.scan-qr-button-block {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 8.4vw;
}