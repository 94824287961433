.carousel-products-block {
  margin-top: 15vw;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 118vw;
}
.carousel-product-card {
  position: absolute;
  width: 50vw;
  height: 118vw;
  scale: .8;
}
.carousel-product-card-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 74vw;
  background-color: #F9F6F2;
  border-radius: 40px;
  color: #BC9458;
  font-family: futura-pt-book;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 5vw;
  font-size: 18px;
}
.carousel-product-card-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 92.8vw;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: top center;
}
.carousel-product-button-left {
  position: absolute;
  left: 0;
  top: 52vw;
  height: 14vw;
  width: 14vw;
  background-image: url('../assets/media/about/slide-button.png');
  background-repeat: no-repeat;
  background-size:  2.8vw 4.8vw;
  background-position: right center;
  cursor: pointer;
}
.carousel-product-button-right {
  transform: scaleX(-1);
  position: absolute;
  right: 0;
  top: 52vw;
  height: 14vw;
  width: 14vw;
  background-image: url('../assets/media/about/slide-button.png');
  background-repeat: no-repeat;
  background-size:  2.8vw 4.8vw;
  background-position: right center;
  cursor: pointer;
}