.data-block {
  width: 100%;
  min-height: 100vh;
}
.data-block::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url('../assets/media/data/bg.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
}
.data-header {
  width: 100%;
  color: #BC9458;
  padding: 21.5vw 5.5vw 0 5.5vw;
  font-family: futura-pt-book;
  font-size: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.data-edit {
  width: 5.09vw;
  height: 5.09vw;
  background-image: url('../assets/media/data/edit.png');
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.data-content-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.data-input-block {
  margin-top: 5vw;
  width: 88.8%;
}
.data-input-label {
  color: #3D3933;
  font-family: futura-pt-book;
  font-size: 14px;
}
.data-input {
  margin-top: 1.4vw;
  color: #3D3933;
  display: block;
  width: 100%;
  height: 11.1vw;
  font-family: futura-pt-book;
  font-size: 16px;
  background-color: #FFFFFF;
  border: 1px solid #84848E;
  border-radius: 20px;
  padding: 0 5vw;
}
.data-input::-webkit-input-placeholder {
 color: #84848E;
 font-family: futura-pt-light;
}
.data-button-block {
  width: 100%;
  height: 35vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.data-error {
  border: 1px solid red !important;
}
.data-tabbar-indent {
  width: 100%;
  height: 30vw;
}