.favorites-block {
  width: 100%;
  min-height: 100vh;
  background-image: url('../assets/media/favorites/bg.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
}
.favorites-info-block {
  margin: 0 auto;
  padding-top: 21vw;
  width: 88.8%;
}
.favorites-title {
  font-family: futura-pt-book;
  color: #BC9458;
  font-size: 22px;
}
.favorites-switch-content {
  margin: 13vw 0;
  position: relative;
  width: 100%;
}
.favorites-switch-content::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 15vw;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  filter: blur(5px);
}
.favorites-switch-block {
  overflow-x: auto;
  white-space: nowrap;
  overflow: -moz-scrollbars-none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  padding: 0 20px;
}
.favorites-switch-block > div {
  padding: 0 7vw;
  height: 10.7vw;
  border-radius: 20px;
  font-family: futura-pt-book;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
}
.favorites-switch-block > div > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.favorites-switch-button-enable {
  color: #FFFFFF;
  background-color: #BC9458;
}
.favorites-switch-button-disable {
  color: #BC9458;
}
.favorites-card-block {
  margin: 7vw auto;
  width: 88.8vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.favorites-row {
  width: 43vw;
}
.favorites-card-indent {
  width: 100%;
  height: 19.4vw;
}
.favorites-tabbar-indent {
  width: 100%;
  height: 30vw;
}