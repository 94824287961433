.tabbar-block {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 30px;
  z-index: 1;
  display: flex;
  justify-content: center;
}
.tabbar {
  width: 83vw;
  height: 22.6vw;
  background-image: url('../assets/media/tabbar/bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: flex-end;
}
.tabbar > div {
  margin-bottom: 4vw;
}
.tabbar-button-1 {
  width: 18vw;
  height: 9vw;
  background-image: url('../assets/media/tabbar/tab-1.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 80% center;
}
.tabbar-button-2 {
  width: 15vw;
  height: 9vw;
  background-image: url('../assets/media/tabbar/tab-2.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.tabbar-button-center {
  width: 100%;
  height: 70%;
  margin-bottom: 7vw !important;
  flex: 1;
}
.tabbar-button-3 {
  width: 15vw;
  height: 9vw;
  background-image: url('../assets/media/tabbar/tab-3.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.tabbar-button-4 {
  width: 18vw;
  height: 9vw;
  background-image: url('../assets/media/tabbar/tab-4.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 20% center;
}
.tab-1-active {
  background-image: url('../assets/media/tabbar/tab-1-active.png') !important;
}
.tab-2-active {
  background-image: url('../assets/media/tabbar/tab-2-active.png') !important;
}
.tab-3-active {
  background-image: url('../assets/media/tabbar/tab-3-active.png') !important;
}
.tab-4-active {
  background-image: url('../assets/media/tabbar/tab-4-active.png') !important;
}