.error-block {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10vw;
  font-family: futura-pt-bold;
  text-align: center;
  font-size: 20px;
  color: #2D2A26;
}