.support-block {
  width: 100%;
  min-height: 100vh;
}
.support-block::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url('../assets/media/support/bg.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
}
.support-header {
  width: 100%;
  color: #BC9458;
  padding: 21.5vw 5.5vw 0 5.5vw;
  font-family: futura-pt-book;
  font-size: 22px;
}
.support-data-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.support-input-block {
  margin-top: 5vw;
  width: 88.8%;
}
.support-input-label {
  color: #3D3933;
  font-family: futura-pt-book;
  font-size: 14px;
}
.support-input {
  margin-top: 1.4vw;
  color: #3D3933;
  display: block;
  width: 100%;
  height: 11.1vw;
  font-family: futura-pt-book;
  font-size: 16px;
  background-color: #FFFFFF;
  border: 1px solid #84848E;
  border-radius: 6vw;
  padding: 0 5vw;
}
.support-input::-webkit-input-placeholder {
 color: #84848E;
 font-family: futura-pt-light;
}
.support-error {
  border: 1px solid red !important;
}
.support-button-block {
  width: 100%;
  height: 35vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.support-textarea {
  margin-top: 1.4vw;
  color: #3D3933;
  display: block;
  width: 100%;
  height: 23.15vw;
  font-family: futura-pt-book;
  font-size: 14px;
  background-color: #FFFFFF;
  border: 1px solid #84848E;
  border-radius: 20px;
  padding: 2vw 4vw;
}
.support-topic-field {
  margin-top: 1.4vw;
  color: #3D3933;
  width: 100%;
  height: 11.1vw;
  font-family: futura-pt-book;
  font-size: 16px;
  background-color: #FFFFFF;
  border: 1px solid #84848E;
  border-radius: 6vw;
  padding: 0 5vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background-image: url('../assets/media/support/arrow.png');
  background-repeat: no-repeat;
  background-position: 79vw center;
  background-size: 5.18vw 5.18vw;
  position: relative;
}
.support-topic-list {
  position: absolute;
  top: 11vw;
  left: 0;
  width: 100%;
  border: 1px solid #BC9458;
  border-radius: 6vw;
  background-color: #FFFFFF;
  z-index: 1;
  padding: 3vw 0;
  overflow: hidden;
}
.support-topic-list > div {
  width: 100%;
  height: 9vw;
  font-family: futura-pt-light;
  font-size: 16px;
  padding: 0 2.77vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.support-acitve-filed {
  border: 1px solid #BC9458 !important;
}
.support-tabbar-indent {
  width: 100%;
  height: 30vw;
}
.support-modal-block {
  position: fixed;
  background-color: rgba(61, 57, 51, .4);
  backdrop-filter: blur(5px);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.support-modal {
  width: 83.3%;
  min-height: 42.4vw;
  background-color: #3D3933;
  background-image: url('../assets/media/support/modal-bg.png');
  background-repeat: no-repeat;
  background-position: 66vw 5vw;
  background-size: 21.57vw 33.4vw;
  border-radius: 20px;
}
.support-modal-header {
  padding-top: 3.5vw;
  width: 100%;
  font-family: futura-pt-book;
  font-size: 24px;
  color: #FFFFFF;
  text-align: center;
}
.support-modal-text {
  padding: 2vw 3.5vw;
  width: 100%;
  font-family: futura-pt-book;
  font-size: 17px;
  color: #FFFFFF;
  text-align: center;
}
.support-modal-button {
  background-color: #BC9458;
  color: #FFFFFF;
  font-size: 16px;
  font-family: futura-pt-book;
  width: 36.57vw;
  height: 11.1vw;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 4vw auto;
}