.rules-block {
  width: 100%;
  min-height: 100vh;
}
.rules-block::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url('../assets/media/rules/bg.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
}
.rules-header {
  width: 100%;
  color: #BC9458;
  padding: 21.5vw 5.5vw 0 5.5vw;
  font-family: futura-pt-book;
  font-size: 22px;
}
.rules-header-content {
  margin: 5vw auto;
  width: 88.8%;
  color: #3D3933;
  font-size: 17px;
  font-family: futura-pt-book;
}
.rules-header-content > a {
  color: #3D3933;
}
.rules-tabbar-indent {
  width: 100%;
  height: 20vw;
}
.rules-blur {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 30vw;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
}