.movements-block {
  width: 100%;
  min-height: 100vh;
}
.movements-block::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  top: 35vw;
  bottom: 0;
  background-image: url('../assets/media/movements/bg.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
}
.movements-header-indent {
  width: 100%;
  height: 24.26vw;
}
.movements-content-block {
  margin: 7vw auto;
  width: 88.8%;
  border-radius: 4vw;
  border: 1px solid #BC9458;
  padding-bottom: 3vw;
}
.movements-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: futura-pt-book;
  font-size: 17px;
  color: #3D3933;
  padding: 2vw 1vw 0 1vw;
}
.movements-row > div {
  width: 33.33%;
  padding-left: 3vw;
}
.movements-empty {
  width: 100%;
  color: #3D3933;
  font-size: 18px;
  font-family: futura-pt-book;
  text-align: center;
  padding: 4vw 0 3vw 0;
}
.movements-tabbar-indent {
  width: 100%;
  height: 30vw;
}