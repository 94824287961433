.product-block {
  width: 100%;
  background-image: url('../assets/media/product/bg.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
}
.prouct-fav-icon {
  position: absolute;
  width: 6vw;
  height: 6vw;
  right: 4.33vw;
  top: 7.3vw;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  z-index: 1;
}
.prouct-fav-icon.enable {
  background-image: url('../assets/media/product/fav-icon-enable.png');
}
.prouct-fav-icon.disable {
  background-image: url('../assets/media/product/fav-icon-disable.png');
}
.product-header-indent {
  width: 100%;
  height: 21vw;
}
.product-cover-block {
  width: 100%;
  height: 121.8vw;
  display: flex;
  justify-content: space-between;
}
.product-cover-label {
  width: 17.13vw;
  height: 100%;
  color: #D02323;
  font-family: futura-pt-bold;
  text-align: right;
  opacity: .2;
  writing-mode: vertical-lr;
  scale: -1;
  padding-bottom: 7.31vw;
}
.product-cover-descr {
  height: 100%;
  flex: 1;
  color: #3D3933;
  font-family: futura-pt-book;
  padding-top: 7.31vw;
}
.product-cover-descr-name {
  font-size: 20px;
  padding-bottom: 2vw;
}
.product-cover-descr-item {
  margin-top: 3vw;
  font-size: 15px;
}
.product-cover-image {
  width: 44vw;
  height: 100%;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.product-content-block {
  margin-top: 4.7vw;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 30px 0 0 0;
}
.product-descr {
  font-family: futura-pt-book;
  font-size: 17px;
  color: #3D3933;
  padding: 6.48vw 5.55vw;
}
.product-recommendations-title {
  margin-top: 4vw;
  color: #3D3933;
  font-size: 22px;
  font-family: futura-pt-book;
  padding: 0 5.55vw;
}
.product-recommendations-block {
  margin-top: 5vw;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  overflow: -moz-scrollbars-none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  padding: 0 2.75vw 0 5.55vw;
}
.product-recommendations-block::-webkit-scrollbar { width: 0; }
.product-recommendations-item {
  margin-top: 10vw;
  position: relative;
  width: 23.5vw;
  height: 36.57vw;
  cursor: pointer;
  flex: 0 0 auto;
  margin-right: 2.8vw;
  border-radius: 15px;
  background-color: #F9F6F2;
}
.product-recommendations-item-image {
  position: absolute;
  width: 100%;
  height: 30vw;
  top: -10vw;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.product-recommendations-item-text {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: flex-end;
  justify-content: center;
  padding: 2.7vw;
  font-family: futura-pt-bold;
  color: #BC9458;
  font-size: 11px;
}
.product-tabbar-indent {
  width: 100%;
  height: 40vw;
}