.cart-block {
  width: 100%;
  background-image: url('../assets/media/cart/bg.png');
  background-position: left 123vw;
  background-repeat: no-repeat;
  background-size: 64.8vw 90.37vw;
}
.cart-header-indent {
  width: 100%;
  height: 21vw;
}
.cart-manage-block {
  margin: 0 auto 6.48vw;
  width: 88.8%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart-choose-all-block {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.cart-choose-icon-enable {
  width: 5.92vw;
  height: 5.92vw;
  background-image: url('../assets/media/checkbox.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cart-choose-icon-disable {
  width: 5.92vw;
  height: 5.92vw;
  border-radius: 50%;
  border: 3px solid #BC9458;
}
.cart-choose-all-text {
  padding-left: 1.5vw;
  font-size: 18px;
  font-family: futura-pt-book;
  color: #3D3933;
}
.cart-remove-all {
  font-size: 18px;
  font-family: futura-pt-book;
  color: #BC9458;
  cursor: pointer;
}
.cart-card-block {
  margin: 0 auto 4.63vw;
  width: 88.8%;
  height: 40.74vw;
  border-radius: 20px;
  background-color: #F9F6F2;
  padding: 2.77vw;
  display: flex;
}
.cart-card-left-block {
  flex: 1;
  position: relative;
}
.cart-card-right-block {
  width: 41.3vw;
  height: 100%;
  position: relative;
}
.cart-card-name {
  font-size: 14px;
  font-family: futura-pt-book;
  color: #3D3933;
}
.cart-card-size {
  font-size: 12px;
  margin: 2vw 0;
  font-family: futura-pt-light;
  color: #BCBCC1;
}
.cart-card-price {
  color: #BC9458;
  font-family: futura-pt-bold;
  font-size: 16px;
}
.cart-card-discount {
  text-decoration: line-through;
  color: #BCBCC1;
  font-family: futura-pt-book;
  font-size: 10px;
  margin-top: 0.8vw;
}
.cart-card-count-block {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 5.09vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.cart-card-minus {
  cursor: pointer;
  width: 5.09vw;
  height: 5.09vw;
  background-image: url('../assets/media/minus-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.cart-card-count {
  font-size: 14px;
  font-family: futura-pt-book;
  color: #3D3933;
  margin: 0 2vw;
}
.cart-card-plus {
  cursor: pointer;
  width: 5.09vw;
  height: 5.09vw;
  background-image: url('../assets/media/plus-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.cart-card-choose {
  position: absolute;
  left: 0;
  top: 0;
}
.cart-card-merch-image {
  width: 100%;
  height: calc(100% - 5.09vw);
  background-size: contain;
  background-position: 75% center;
  background-repeat: no-repeat;
}
.cart-card-manage-block {
  width: 100%;
  height: 5.09vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cart-card-favorite {
  cursor: pointer;
  width: 5.09vw;
  height: 5.09vw;
  background-size: contain;
  background-repeat: no-repeat;
}
.cart-card-favorite.enable {
  background-image: url('../assets/media/favorite-icon-enable.png');
}
.cart-card-favorite.disable {
  background-image: url('../assets/media/favorite-icon-disable.png');
}
.cart-card-remove {
  cursor: pointer;
  width: 5.09vw;
  height: 5.09vw;
  background-image: url('../assets/media/cart/card-remove-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 2vw;
}
.cart-prmocode-block {
  width: 88.8%;
  height: 11.1vw;
  margin: 12.96vw auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart-prmocode-input {
  display: block;
  color: #3D3933;
  font-family: futura-pt-book;
  font-size: 16px;
  background-color: #FFFFFF;
  border: 1px solid #BCBCC1;
  border-radius: 20px;
  padding: 0 5vw;
  height: 100%;
  width: 42.13vw;
}
.cart-prmocode-input::-webkit-input-placeholder {
  color: #84848E;
  font-family: futura-pt-light;
 }
.cart-prmocode-button {
  height: 100%;
  width: 42.13vw;
  color: #FFFFFF;
  font-size: 16px;
  font-family: futura-pt-book;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #BC9458;
}
.cart-info-block {
  width: 88.8%;
  margin: 12.96vw auto 0
}
.cart-info-line {
  margin-top: 2vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-family: futura-pt-book;
  color: #3D3933;
}
.cart-registration-button {
  margin: 12.96vw auto 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #BC9458;
  color: #FFFFFF;
  font-size: 14px;
  font-family: futura-pt-book;
  width: 88.8%;
  height: 12.9vw;
  border-radius: 30px;
  cursor: pointer;
}
.cart-tabbar-indent {
  width: 100%;
  height: 40vw;
}
.cart-scroll-block {
  transition: all 300ms ease-in-out;
}
.cart-short-info-block {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50.92vw;
  z-index: 0;
  background-color: #FFFFFF;
  border-top: 1px solid #BC9458;
  border-radius: 20px 20px 0 0;
  transition: all 300ms ease-in-out;
}
.cart-short-info {
  margin: 2.77vw auto;
  width: 88.8%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart-short-info-price {
  color: #BC9458;
  font-family: futura-pt-bold;
  font-size: 20px;
}
.cart-short-info-button {
  height: 11.1vw;
  width: 54.07vw;
  color: #FFFFFF;
  font-size: 14px;
  font-family: futura-pt-book;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #BC9458;
}
.cart-empty-block {
  margin-top: 10vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cart-empty-text {
  font-family: futura-pt-bold;
  color: #3D3933;
  font-size: 20px;
  padding: 10vw 0;
}
.cart-modal-block {
  position: fixed;
  background-color: rgba(61, 57, 51, .4);
  backdrop-filter: blur(5px);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart-modal {
  width: 83.3%;
  height: 40vw;
  background-color: #3D3933;
  background-image: url('../assets/media/cart/modal-bg.png');
  background-repeat: no-repeat;
  background-position: 66vw 5vw;
  background-size: 21.57vw 33.4vw;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.cart-modal-header {
  width: 100%;
  font-family: futura-pt-book;
  font-size: 24px;
  color: #FFFFFF;
  text-align: center;
  margin-top: 3vw;
}
.cart-modal-button {
  margin: 0 auto;
  color: #FFFFFF;
  font-size: 16px;
  font-family: futura-pt-book;
  width: 36.57vw;
  height: 11.1vw;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #BC9458;
  margin-bottom: 3vw;
}