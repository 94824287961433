.scan-choose-block {
  position: relative;
  width: 100%;
  min-height: 100vh;
}
.scan-choose-buttons-block {
  width: 100%;
  height: 30vw;
  position: absolute;
  bottom: 8vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.scan-choose-qr {
  margin: 5vw auto 0;
  width: 65vw;
  height: 65vw;
  background-image: url('../assets/media/scan-choose/qr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.scan-choose-descr {
  padding-top: 26vw;
  margin: 0 auto;
  width: 88.8%;
  font-size: 16px;
  color: #3D3933;
  text-align: center;
  font-family: futura-pt-book;
}