.questionnaire-block {
  width: 100%;
  background-image: url('../assets/media/questionnaire/bg.png');
  background-repeat: no-repeat;
  background-size: 64.8vw 90.3vw;
  background-position: 0 50vw;
}
.questionnaire-team-block {
  margin-top: 23vw;
  width: 100%;
  text-align: center;
}
.questionnaire-team-title {
  text-transform: uppercase;
  color: #3D3933;
  font-family: Cormorant-Bold;
  font-size: 30px;
  line-height: 33px;
}
.questionnaire-team-santo {
  text-transform: uppercase;
  color: #BC9458;
  font-family: Calypso;
  font-size: 38px;
}
.questionnaire-data-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.questionnaire-input-block {
  margin-top: 4vw;
  width: 88.8%;
}
.questionnaire-input-label {
  color: #3D3933;
  font-family: futura-pt-book;
  font-size: 14px;
}
.questionnaire-input {
  margin-top: 1.4vw;
  color: #3D3933;
  display: block;
  width: 100%;
  height: 11.1vw;
  font-family: futura-pt-book;
  font-size: 16px;
  background-color: #FFFFFF;
  border: 1px solid #84848E;
  border-radius: 20px;
  padding: 0 5vw;
}
.questionnaire-textarea {
  margin-top: 1.4vw;
  color: #3D3933;
  display: block;
  width: 100%;
  height: 23.15vw;
  font-family: futura-pt-book;
  font-size: 16px;
  background-color: #FFFFFF;
  border: 1px solid #84848E;
  border-radius: 20px;
  padding: 2vw 4vw;
}
.questionnaire-input::-webkit-input-placeholder {
  color: #84848E;
  font-family: futura-pt-light;
}
.questionnaire-textarea::-webkit-input-placeholder {
  color: #84848E;
  font-family: futura-pt-light;
}
.questionnaire-switch-block {
  margin-top: 4.63vw;
  background-color: #F4F4F4;
  height: 12.59vw;
  width: 88.8%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.questionnaire-switch-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3D3933;
  font-family: futura-pt-book;
  font-size: 16px;
  width: 42.59vw;
  height: 8.88vw;
  border-radius: 20px;
}
.questionnaire-file-block {
  width: 88.8%;
  height: 12.59vw;
  margin-top: 4.63vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.questionnaire-file-choose-button {
  width: 40.9vw;
  height: 100%;
  background-color: #F4F4F4;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3D3933;
  font-family: futura-pt-book;
  font-size: 16px;
  cursor: pointer;
}
.questionnaire-file-choose-descr {
  flex: 1;
  height: 100%;
  color: #84848E;
  font-family: futura-pt-book;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.questionnaire-filename-block {
  margin-top: 1.5vw;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.questionnaire-filename {
  font-family: futura-pt-bold;
  font-size: 15px;
  color: #000000;
}
.questionnaire-file-cancel {
  background-image: url('../assets/media/questionnaire/cancel.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 5.185vw;
  height: 5.185vw;
}
.questionnaire-checkbox-block {
  margin-top: 6vw;
  width: 88.8%;
  text-decoration: underline;
  color: #BC9458;
  font-family: futura-pt-book;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.questionnaire-checkbox-block > a {
  color: #BC9458;
}
.questionnaire-checkbox {
  width: 6vw;
  height: 6vw;
  border-radius: 50%;
  margin-right: 2vw;
  cursor: pointer;
}
.questionnaire-checkbox-enable {
  background-image: url('../assets/media/checkbox.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.questionnaire-checkbox-disable {
  background-color: #FFFFFF;
  border: 3px solid #BC9458;
}
.questionnaire-button-block {
  margin-top: 4vw;
  width: 100%;
  height: 35vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.questionnaire-error {
  border: 1px solid red !important;
}
.questionnaire-modal-block {
  position: fixed;
  background-color: rgba(61, 57, 51, .4);
  backdrop-filter: blur(5px);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.questionnaire-modal {
  position: relative;
  width: 83.3%;
  min-height: 42.4vw;
  background-color: #3D3933;
  background-image: url('../assets/media/questionnaire/modal-bg.png');
  background-repeat: no-repeat;
  background-position: 66vw 5vw;
  background-size: 21.57vw 33.4vw;
  border-radius: 20px;
}
.questionnaire-modal-text {
  padding: 8vw 3.5vw;
  width: 100%;
  font-family: futura-pt-book;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;
}
.questionnaire-modal-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 22vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.questionnaire-modal-buttons > div {
  color: #FFFFFF;
  font-size: 16px;
  font-family: futura-pt-book;
  width: 36.57vw;
  height: 11.1vw;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #BC9458;
}