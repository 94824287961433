.merch-card-block {
  width: 42vw;
  margin-bottom: 4vw;
}
.merch-card-image {
  width: 100%;
  height: 64.8vw;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 34px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.merch-card-favorit-icon {
  position: absolute;
  top: 4vw;
  right: 3vw;
  width: 4.9vw;
  height: 4.9vw;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.merch-card-favorit-icon.disable {
  background-image: url('../assets/media/favorite-icon-disable.png');
}
.merch-card-favorit-icon.enable {
  background-image: url('../assets/media/favorite-icon-enable.png');
}
.merch-card-tag {
  position: absolute;
  left: 0;
  top: 4vw;
  padding: 0 3vw;
  height: 5.92vw;
  background-color: #BC9458;
  color: #FFFFFF;
  font-family: futura-pt-light;
  font-size: 12px;
  display: flex;
  align-items: center;
  border-radius: 0 15px 15px 0;
}
.merch-card-name {
  margin-top: 2vw;
  color: #3D3933;
  font-family: futura-pt-bold;
  font-size: 11px;
  width: 80%;
}
.merch-card-price {
  margin-top: 2vw;
  color: #BC9458;
  font-family: futura-pt-bold;
  font-size: 14px;
}
.merch-card-price > span {
  text-decoration: line-through;
  color: #BCBCC1;
  font-family: futura-pt-light;
  font-size: 11px;
}