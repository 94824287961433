.status-block {
  width: 88.8%;
  height: 48.7vw;
  margin: 0 auto;
  padding: 4.63vw;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #FFFFFF;
  font-family: futura-pt-book;
  border-radius: 7px;
}
.status-block.gold {
  background-image: url('../assets/media/statuses/gold.png');
}
.status-block.silver {
  background-image: url('../assets/media/statuses/silver.png');
}
.status-block.bronze {
  background-image: url('../assets/media/statuses/bronze.png');
}
.status-header-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.status-name {
  font-size: 22px;
}
.status-id {
  font-size: 16px;
}
.status-bottom-block {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.status-scores {
  font-size: 22px;
}
.status-link {
  font-size: 12px;
  text-decoration: underline;
}