.carousel-merchs-block {
  margin-top: 6vw;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 85vw;
}
.carousel-merch-card {
  position: absolute;
  width: 64vw;
  height: 74vw;
  scale: .8;
}
.carousel-merch-card-content {
  position: absolute;
  bottom: 0;
  width: 55vw;
  height: 74vw;
  background-color: #F9F6F2;
  border-radius: 50px;
  padding: 5vw;
}
.carousel-merch-card-name {
  font-family: futura-pt-book;
  color: #3D3933;
  font-size: 20px;
  padding-top: 1.5vw;
}
.carousel-merch-card-price {
  margin-top: 3.2vw;
  color: #BC9458;
  font-family: futura-pt-bold;
  font-size: 16px;
}
.carousel-merch-card-discount {
  text-decoration: line-through;
  color: #84848E;
  font-family: futura-pt-book;
  font-size: 12px;
}
.carousel-merch-card-image {
  position: absolute;
  right: 0;
  width: 43vw;
  height: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom center;
}
.carousel-merch-button-left {
  position: absolute;
  right: 14vw;
  bottom: 0;
  height: 14vw;
  width: 14vw;
  background-image: url('../assets/media/about/slide-button.png');
  background-repeat: no-repeat;
  background-size:  2.8vw 4.8vw;
  background-position: center center;
  cursor: pointer;
}
.carousel-merch-button-right {
  transform: scaleX(-1);
  position: absolute;
  right: 0;
  bottom: 0;
  height: 14vw;
  width: 14vw;
  background-image: url('../assets/media/about/slide-button.png');
  background-repeat: no-repeat;
  background-size:  2.8vw 4.8vw;
  background-position: center center;
  cursor: pointer;
}