.orders-block {
  width: 100%;
  min-height: 100vh;
}
.orders-block::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url('../assets/media/orders/bg.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
}
.orders-header {
  width: 100%;
  color: #BC9458;
  padding: 21.5vw 5.5vw 0 5.5vw;
  font-family: futura-pt-book;
  font-size: 22px;
}
.orders-merch-block {
  width: 88.8%;
  padding: 2.77vw 0 2.77vw 2.77vw;
  background-color: #F9F6F2;
  border-radius: 15px;
  margin: 5vw auto 0;
  display: flex;
  flex-wrap: wrap;
}
.orders-item {
  width: 16.2vw;
  height: 16.2vw;
  border-radius: 15px;
  background-color: #FFFFFF;
  margin: 2.77vw 2.77vw 2.77vw 0;
  background-position: center center;
  background-size: 90% 90%;
  background-repeat: no-repeat;
}
.orders-info-block {
  width: 100%;
  padding-top: 0.5vw;
  padding-right: 2.77vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.orders-info-status {
  background-color: #BC9458;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.7vw 5vw;
  font-family: futura-pt-light;
  color: #FFFFFF;
  font-size: 10px;
  border-radius: 5vw;
}
.orders-info-id {
  font-family: futura-pt-bold;
  font-size: 17px;
  color: #BC9458;
}
.orders-info-date {
  font-family: futura-pt-book;
  color: #84848E;
  font-size: 16px;
}
.orders-address {
  width: 100%;
  font-family: futura-pt-book;
  color: #3D3933;
  font-size: 14px;
  line-height: 14px;
  padding-top: 2vw;
  padding-bottom: 3vw;
  padding-right: 2.77vw;
}
.orders-empty-block {
  margin-top: 10vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.orders-empty-text {
  font-family: futura-pt-bold;
  color: #3D3933;
  font-size: 20px;
  padding: 10vw 0;
}
.orders-tabbar-indent {
  width: 100%;
  height: 40vw;
}