.club-block {
  width: 100%;
  min-height: 100vh;
  background-image: url('../assets/media/club/bg.jpg');
  background-size: 100% auto;
  background-position: top center;
}
.club-avatar {
  position: absolute;
  left: 5.55vw;
  top: 6vw;
  width: 9.25vw;
  height: 9.25vw;
  border-radius: 50%;
  border: 1px solid #3D3933;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.club-header-scores {
  position: absolute;
  right: 5.55vw;
  top: 7.5vw;
  font-size: 17px;
  text-align: right;
  font-family: futura-pt-bold;
}
.club-header-indent {
  width: 100%;
  height: 24.26vw;
}
.club-referral-header {
  padding: 0 2vw;
  margin-top: 12.96vw;
  width: 100%;
  font-family: Cormorant-Bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 30px;
}
.club-referral-santo {
  margin-top: 0.6vw;
  width: 100%;
  color: #BC9458;
  font-family: Calypso;
  text-align: center;
  text-transform: uppercase;
  font-size: 40px;
}
.club-referral-descr {
  margin-top: 3.7vw;
  font-family: futura-pt-light;
  text-align: center;
  color: #3D3933;
  font-size: 21px;
  line-height: 21px;
}
.club-referral-button {
  margin: 6.48vw auto 0;
  width: 88.8%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3D3933;
  padding: 2.6vw 0;
  color: #BC9458;
  font-family: futura-pt-book;
  font-size: 22px;
  cursor: pointer;
}
.club-scan-header {
  margin-top: 12.96vw;
  width: 100%;
  color: #3D3933;
  text-transform: uppercase;
  font-size: 29px;
  font-family: futura-pt-book;
  text-align: center;
}
.club-scan-header > span {
  color: #BC9458;
  font-family: Calypso;
  font-size: 43px;
}
.club-scan-descr {
  margin-top: 2vw;
  font-family: futura-pt-light;
  color: #3D3933;
  font-size: 21px;
  line-height: 21px;
  text-align: center;
}
.club-scan-button-block {
  margin: 7.4vw auto 14vw;
  width: 88.8%;
  display: flex;
  justify-content: center;
}
.club-scan-button {
  width: 58vw;
  height: 18.6vw;
  background-image: url('../assets/media/club/scan-button.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  cursor: pointer;
}
.club-menu-button {
  margin: 3.7vw auto 0;
  width: 88.8%;
  height: 11.1vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: futura-pt-book;
  color: #3D3933;
  font-size: 18px;
  border-radius: 6vw;
  border: 1px solid #BC9458;
  padding-left: 4.16vw;
  background-color: #FFFFFF;
  background-image: url('../assets/media/club/arrow.png');
  background-repeat: no-repeat;
  background-position: 79vw center;
  background-size: 5.18vw 5.18vw;
}
.club-tabbar-indent {
  width: 100%;
  height: 40vw;
}




.club-modal-block {
  position: fixed;
  background-color: rgba(61, 57, 51, .4);
  backdrop-filter: blur(5px);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.club-modal {
  width: 83.3%;
  min-height: 42.4vw;
  background-color: #3D3933;
  background-image: url('../assets/media/club/modal-bg.png');
  background-repeat: no-repeat;
  background-position: 66vw 5vw;
  background-size: 21.57vw 33.4vw;
  border-radius: 20px;
}
.club-modal-header {
  padding: 7vw 3.5vw 3.5vw 3.5vw;
  width: 100%;
  font-family: futura-pt-book;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;
}
.club-modal-text {
  padding: 2vw 3.5vw;
  width: 100%;
  font-family: futura-pt-book;
  font-size: 17px;
  color: #FFFFFF;
}
.club-modal-buttons {
  width: 100%;
  height: 22vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4vw;
}
.club-modal-button-gold {
  color: #FFFFFF;
  font-size: 16px;
  font-family: futura-pt-book;
  width: 36.57vw;
  height: 11.1vw;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #BC9458;
}