.products-block {
  width: 100%;
  background-image: url('../assets/media/products/bottle.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
}
.products-title-block {
  width: 100%;
  height: 139.4vw;
  color: #FFFFFF;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: futura-pt-book;
  font-size: 34px;
  padding-left: 7vw;
  padding-bottom: 18vw;
}
.products-content-block {
  width: 100%;
  background-image: url('../assets/media/products/bg.jpg');
  background-size: 100% auto;
  background-position: top center;
  border-radius: 30px 0 0 0;
}
.products-descr {
  font-family: futura-pt-book;
  font-size: 16px;
  color: #3D3933;
  padding: 6.48vw 5.55vw;
}
.products-switch-content {
  margin-top: 7vw;
  position: relative;
  width: 100%;
}
.products-switch-content::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 15vw;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  filter: blur(5px);
}
.products-switch-block {
  overflow-x: auto;
  white-space: nowrap;
  overflow: -moz-scrollbars-none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  padding: 0 20px;
}
.products-switch-block::-webkit-scrollbar { width: 0; }
.products-switch-block > div {
  padding: 0 7vw;
  height: 10.7vw;
  border-radius: 20px;
  font-family: futura-pt-book;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
}
.products-switch-block > div > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.products-switch-button-enable {
  color: #FFFFFF;
  background-color: #BC9458;
}
.products-switch-button-disable {
  color: #BC9458;
}
.products-card-block {
  margin: 7vw auto;
  width: 88.8vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.products-row {
  width: 43vw;
}
.products-card-indent {
  width: 100%;
  height: 19.4vw;
}
.products-tabbar-indent {
  width: 100%;
  height: 26vw;
}