.recipe-block {
  width: 100%;
  background-image: url('../assets/media/recipe/bg.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
}
.recipe-fav-icon {
  position: absolute;
  width: 6vw;
  height: 6vw;
  right: 4.33vw;
  top: 7.3vw;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  z-index: 1;
}
.recipe-fav-icon.enable {
  background-image: url('../assets/media/recipe/fav-icon-enable.png');
}
.recipe-fav-icon.disable {
  background-image: url('../assets/media/recipe/fav-icon-disable.png');
}
.recipe-header-indent {
  width: 100%;
  height: 21vw;
}
.recipe-cover-block {
  width: 100%;
  height: 121.8vw;
  display: flex;
  justify-content: space-between;
}
.recipe-cover-label {
  width: 17.13vw;
  height: 100%;
  color: #D02323;
  font-family: futura-pt-bold;
  text-align: right;
  opacity: .2;
  writing-mode: vertical-lr;
  font-size: 70px;
  scale: -1;
  padding-bottom: 7.31vw;
}
.recipe-cover-descr {
  height: 100%;
  flex: 1;
  color: #3D3933;
  font-family: futura-pt-book;
  padding-top: 7.31vw;
}
.recipe-cover-descr-name {
  font-size: 20px;
  padding-bottom: 2vw;
}
.recipe-cover-descr-item {
  margin-top: 3vw;
  font-size: 15px;
}
.recipe-cover-image {
  width: 44vw;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.recipe-content-block {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 30px 0 0 0;
}
.recipe-descr-header {
  font-family: futura-pt-book;
  font-size: 18px;
  color: #3D3933;
  padding: 6.48vw 5.55vw 4vw 5.55vw;
}
.recipe-descr-text {
  font-family: futura-pt-book;
  font-size: 18px;
  color: #3D3933;
  padding: 0 5.55vw 0 5.55vw;
}
.recipe-video-title {
  margin-top: 13vw;
  color: #3D3933;
  font-size: 22px;
  font-family: futura-pt-book;
  padding: 0 5.55vw;
}
.recipe-video-container {
  position: relative;
  width: 88.8%;
  margin: 5vw auto 0;
  padding: 1px 1px 0 1px;
}
.recipe-video-container > video {
  object-fit: contain;
  width: 100%;
  border: 0;
}
.recipe-video-container::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: url('../assets/media/recipe/video-borders.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.recipe-video-play-btn {
  position: absolute;
  width: 7.4vw;
  height: 7.4vw;
  left: calc(50% - 3.7vw);
  top: calc(50% - 3.7vw);
  cursor: pointer;
  background-image: url('../assets/media/recipe/play.png');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 100;
}
.recipe-recommendations-title {
  margin-top: 10vw;
  color: #3D3933;
  font-size: 22px;
  font-family: futura-pt-book;
  padding: 0 5.55vw;
}
.recipe-recommendations-block {
  margin-top: 5vw;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  overflow: -moz-scrollbars-none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  padding: 0 2.75vw 0 5.55vw;
}
.recipe-recommendations-block::-webkit-scrollbar { width: 0; }



.recipe-tabbar-indent {
  width: 100%;
  height: 40vw;
}