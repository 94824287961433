.faq-block {
  width: 100%;
  min-height: 100vh;
}
.faq-block::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url('../assets/media/faq/bg.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
}
.faq-header {
  width: 100%;
  color: #BC9458;
  padding: 21.5vw 5.5vw 0 5.5vw;
  font-family: futura-pt-book;
  font-size: 22px;
}
.faq-item-short {
  margin: 3.7vw auto 0;
  width: 88.8%;
  padding: 3vw 10vw 3vw 4vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: futura-pt-book;
  color: #3D3933;
  font-size: 18px;
  line-height: 18px;
  border-radius: 6vw;
  border: 1px solid #BC9458;
  background-color: #FFFFFF;
  background-image: url('../assets/media/faq/arrow.png');
  background-repeat: no-repeat;
  background-position: 79vw center;
  background-size: 5.18vw 5.18vw;
}
.faq-item-full {
  margin: 3.7vw auto 0;
  width: 88.8%;
  border-radius: 5vw;
  border: 1px solid #BC9458;
  padding: 0 2.77vw;
  background-color: #FFFFFF;
  background-image: url('../assets/media/faq/arrow-down.png');
  background-repeat: no-repeat;
  background-position: 80.5vw 2.5vw;
  background-size: 5.18vw 5.18vw;
}
.faq-item-header {
  width: 100%;
  cursor: pointer;
  padding: 3vw 7vw 3vw 0;
  font-family: futura-pt-book;
  color: #3D3933;
  font-size: 18px;
  line-height: 18px;
}
.faq-item-descr {
  font-family: futura-pt-light;
  font-size: 18px;
  line-height: 18px;
  color: #3D3933;
  padding-bottom: 3vw;
}
.faq-support-descr {
  width: 88%;
  margin: 6vw auto;
  color: #3D3933;
  font-family: futura-pt-book;
  font-size: 18px;
  line-height: 18px;
}
.faq-button-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.faq-tabbar-indent {
  width: 100%;
  height: 40vw;
}