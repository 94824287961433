.reg-block {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-image: url('../assets/media/reg/bg.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
}
.reg-header {
  width: 100%;
  color: #BC9458;
  padding-left: 5.5vw;
  padding-top: 21.5vw;
  font-family: futura-pt-book;
  font-size: 22px;
}
.reg-text {
  width: 100%;
  padding: 5.5vw;
  color: #3D3933;
  font-family: futura-pt-book;
  font-size: 17px;
}
.reg-text > a {
  color: #3D3933;
}
.reg-buttons-block {
  width: 100%;
  height: 30vw;
  position: absolute;
  bottom: 8vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.reg-modal-block {
  position: fixed;
  background-color: rgba(61, 57, 51, .4);
  backdrop-filter: blur(5px);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reg-modal {
  width: 83.3%;
  min-height: 42.4vw;
  background-color: #3D3933;
  background-image: url('../assets/media/reg/modal-bg.png');
  background-repeat: no-repeat;
  background-position: 66vw 5vw;
  background-size: 21.57vw 33.4vw;
  border-radius: 20px;
}
.reg-modal-header {
  padding-top: 3.5vw;
  width: 100%;
  font-family: futura-pt-book;
  font-size: 24px;
  color: #FFFFFF;
  text-align: center;
}
.reg-modal-text {
  padding: 2vw 3.5vw;
  width: 100%;
  font-family: futura-pt-book;
  font-size: 17px;
  color: #FFFFFF;
}
.reg-modal-buttons {
  width: 100%;
  height: 22vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4vw;
}
.reg-modal-buttons > div {
  color: #FFFFFF;
  font-size: 16px;
  font-family: futura-pt-book;
  width: 36.57vw;
  height: 11.1vw;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.reg-modal-button-gold {
  background-color: #BC9458;
}
.reg-data-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reg-input-block {
  margin-top: 5vw;
  width: 88.8%;
}
.reg-input-label {
  color: #3D3933;
  font-family: futura-pt-book;
  font-size: 14px;
}
.reg-input {
  margin-top: 1.4vw;
  color: #3D3933;
  display: block;
  width: 100%;
  height: 11.1vw;
  font-family: futura-pt-book;
  font-size: 16px;
  background-color: #FFFFFF;
  border: 1px solid #84848E;
  border-radius: 20px;
  padding: 0 5vw;
}
.reg-input::-webkit-input-placeholder {
 color: #84848E;
 font-family: futura-pt-light;
}
.reg-checkbox-block {
  margin-top: 7vw;
  width: 88.8%;
  text-decoration: underline;
  color: #BC9458;
  font-family: futura-pt-book;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.reg-checkbox-block > a {
  color: #BC9458;
}
.reg-checkbox {
  width: 6vw;
  height: 6vw;
  border-radius: 50%;
  margin-right: 2vw;
  cursor: pointer;
}
.reg-checkbox-enable {
  background-image: url('../assets/media/checkbox.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.reg-checkbox-disable {
  background-color: #FFFFFF;
  border: 3px solid #BC9458;
}
.reg-button-block {
  width: 100%;
  height: 35vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reg-error {
  border: 1px solid red !important;
}