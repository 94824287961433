.about-block {
  width: 100%;
  background-image: url('../assets/media/about/bottle.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
}
.about-title-block {
  width: 100%;
  height: 139.4vw;
  color: #FFFFFF;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: futura-pt-light;
  font-size: 38px;
  padding-left: 5.6vw;
  padding-bottom: 6.57vw;
}
.about-title-block > span {
  font-family: futura-pt-book;
  line-height: 40px;
  font-size: 54px;
}
.about-content-block {
  width: 100%;
  min-height: 300vw;
  background-image: url('../assets/media/about/bg.jpg');
  background-size: 100% auto;
  background-position: top center;
  border-radius: 30px 0 0 0;
}
.about-descr {
  font-family: futura-pt-book;
  font-size: 16px;
  color: #3D3933;
  padding: 6.48vw 5.55vw;
}
.about-products-title-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: futura-pt-book;
  padding: 0 5.55vw;
  margin-top: 13vw;
}
.about-products-title {
  text-transform: uppercase;
  color: #3D3933;
  font-size: 22px;
}
.about-products-title-all {
  color: #BC9458;
  font-size: 18px;
  cursor: pointer;
}
.about-team-block {
  margin-top: 23vw;
  width: 100%;
  text-align: center;
}
.about-team-title {
  text-transform: uppercase;
  color: #3D3933;
  font-family: Cormorant-Bold;
  font-size: 30px;
  line-height: 33px;
}
.about-team-santo {
  text-transform: uppercase;
  color: #BC9458;
  font-family: Calypso;
  font-size: 38px;
}
.about-team-work-header {
  margin-top: 4vw;
  text-transform: uppercase;
  color: #3D3933;
  font-family: FuturaPTBook;
  font-size: 14px;
}
.about-team-work-descr {
  color: #3D3933;
  font-family: futura-pt-book;
  font-size: 16px;
  margin-top: 1vw;
}
.about-questionnaire-button-block {
  margin-top: 7vw;
  width: 100%;
  display: flex;
  justify-content: center;
}
.about-questionnaire-button {
  font-family: FuturaPTBook;
  font-size: 22px;
  color: #BC9458;
  border: .4vw solid #3D3933;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vw 5vw;
  cursor: pointer;
  height: 12.2vw;
  width: 72.6vw;
}
.about-tabbar-indent {
  width: 100%;
  height: 50vw;
}