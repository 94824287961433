.store-block {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-image: url('../assets/media/store/bg.png');
  background-size: 100% auto;
}
.store-favorit-icon {
  position: absolute;
  top: 6.8vw;
  right: 15vw;
  width: 6.95vw;
  height: 6.95vw;
  background-image: url('../assets/media/store/favourites-icon.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.store-cart-icon {
  position: absolute;
  top: 6.8vw;
  right: 4.5vw;
  width: 6.95vw;
  height: 6.95vw;
  background-image: url('../assets/media/store/cart-icon.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.store-cart-mark {
  position: absolute;
  width: 2.5vw;
  height: 2.5vw;
  background-color: #BC9458;
  color: #FFFFFF;
  border-radius: 50%;
  top: 0vw;
  right: -1vw;
  font-size: 10px;
  font-family: futura-pt-light;
}
.store-scores-block {
  padding-top: 26vw;
  width: 100%;
  display: flex;
  justify-content: center;
}
.store-scores {
  width: 88.8vw;
  height: 25.9vw;
  padding: 5.5vw 4vw;
  background-image: url('../assets/media/store/scores.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.store-name {
  font-size: 18px;
  color: #FFFFFF;
  font-family: futura-pt-book;
}
.store-balance {
  margin-top: 2.2vw;
  font-size: 24px;
  color: #FFFFFF;
  font-family: futura-pt-bold;
}
.store-search-block {
  margin-top: 7vw;
  width: 100%;
  display: flex;
  justify-content: center;
}
.store-search {
  display: block;
  width: 88.8vw;
  height: 9vw;
  border-radius: 20px;
  font-size: 16px;
  font-family: futura-pt-book;
  color: #BC9458;
  border: 1px solid #BC9458;
  padding: 0 8.5vw;
  background-image: url('../assets/media/store/search.png');
  background-size: 3.15vw 3.15vw;
  background-position: 3.6% center;
  background-repeat: no-repeat;
}
.store-search::-webkit-input-placeholder {
  color: #BC9458;
  font-family: futura-pt-light;
}
.store-switch-content {
  position: relative;
  width: 100%;
}
.store-switch-content::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 15vw;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  filter: blur(5px);
}
.store-switch-block {
  margin: 7vw auto 0;
  overflow-x: auto;
  white-space: nowrap;
  overflow: -moz-scrollbars-none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  padding: 0 20px;
}
.store-switch-block::-webkit-scrollbar { width: 0; }
.store-switch-block > div {
  padding: 0 7vw;
  height: 10.7vw;
  border-radius: 20px;
  font-family: futura-pt-book;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
}
.store-switch-block > div > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.store-switch-button-enable {
  color: #FFFFFF;
  background-color: #BC9458;
}
.store-switch-button-disable {
  color: #BC9458;
}
.store-cart-button-block {
  margin-top: 10vw;
  margin-bottom: 5vw;
  width: 100%;
  display: flex;
  justify-content: center;
}
.store-products-block {
  margin: 7vw auto;
  width: 88.8vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.store-product-indent {
  width: 100%;
  height: 12vw;
}
.store-product-cart-button {
  margin-top: 4vw;
  width: 100%;
  height: 9.26vw;
  background-color: #BC9458;
  color: #FFFFFF;
  border-radius: 20px;
  font-family: futura-pt-light;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
}
.store-modal-block {
  position: fixed;
  background-color: rgba(61, 57, 51, .4);
  backdrop-filter: blur(5px);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.store-modal {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 74vw;
  background-color: #3D3933;
  background-image: url('../assets/media/store/modal-bg.png');
  background-repeat: no-repeat;
  background-position: right 3vw;
  background-size: 21.6vw 54.9vw;
  border-radius: 20px 20px 0 0;
}
.store-modal-header {
  font-family: futura-pt-book;
  text-align: center;
  color: #FFFFFF;
  font-size: 22px;
  padding-top: 4vw;
}
.store-modal-descr {
  width: 92.5vw;
  margin: 0 auto;
  font-family: futura-pt-book;
  color: #FFFFFF;
  font-size: 17px;
  padding-top: 3vw;
}
.store-modal-buttons-block {
  width: 100%;
  height: 25vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 4vw;
}
.store-modal-buttons-block > div {
  cursor: pointer;
  width: 92.5vw;
  height: 11.1vw;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-family: futura-pt-book;
  font-size: 15px;
}
.store-modal-button {
  background-color: #BC9458;
}
.store-tabbar-indent {
  width: 100%;
  height: 30vw;
}