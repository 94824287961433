.scan-manual-block {
  position: relative;
  width: 100%;
  min-height: 177.7vw;
  background-image: url('../assets/media/scan-manual/bg.png');
  background-position: left 75vw;
  background-repeat: no-repeat;
  background-size: 65.7vw 90.9vw;
}
.scan-manual-header {
  width: 100%;
  color: #BC9458;
  padding-left: 5.5vw;
  padding-top: 21.5vw;
  font-family: futura-pt-book;
  font-size: 22px;
}
.scan-manual-descr {
  padding-top: 5vw;
  margin: 0 auto;
  width: 88.8%;
  font-size: 16px;
  color: #3D3933;
  font-family: futura-pt-book;
}
.scan-manual-example-block {
  margin: 8vw auto 0;
  width: 100%;
  padding-left: 4vw;
  padding-right: 3.5vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.scan-manual-example-image {
  width: 51.6vw;
  min-height: 76.1vw;
  background-image: url('../assets/media/scan-manual/bill.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.scan-manual-example-fields {
  padding-top: 16vw;
  color: #3D3933;
  font-family: futura-pt-book;
  font-size: 9.5px;
  display: flex;
  flex-direction: column;
}
.scan-manual-example-fields > div {
  margin-top: 2vw;
  padding-left: 2vw;
  border: 1px solid #D02323;
  width: 34.7vw;
  height: 4.63vw;
  display: flex;
  align-items: center;
}
.scan-manual-data-block {
  margin-top: 3vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scan-manual-input-block {
  margin-top: 4vw;
  width: 88.8%;
}
.scan-manual-input-label {
  color: #3D3933;
  font-family: futura-pt-book;
  font-size: 14px;
}
.scan-manual-input {
  margin-top: 1.4vw;
  color: #3D3933;
  display: block;
  width: 100%;
  height: 11.1vw;
  font-family: futura-pt-book;
  font-size: 16px;
  background-color: #FFFFFF;
  border: 1px solid #84848E;
  border-radius: 20px;
  padding: 0 5vw;
}
.scan-manual-input::-webkit-input-placeholder {
 color: #84848E;
 font-family: futura-pt-light;
}
.scan-manual-buttons-block {
  margin-top: 14vw;
  margin-bottom: 16vw;
  width: 100%;
  height: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.scan-manual-error {
  border: 1px solid red !important;
}
.scan-manual-tabbar-indent {
  width: 100%;
  height: 20vw;
}