.story-block {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, .75);
}
.story-content {
  position: absolute;
  left: 6.1vw;
  right: 6.1vw;
  top: 4.8vw;
  bottom: 0;
  background-color: #D9D9D9;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}
.story-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 18vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.story-descr {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3D3933;
  background-image: url('../assets/media/stories/logo.png');
  background-repeat: no-repeat;
  background-size: 21.6vw 54.2vw;
  background-position: top right;
  color: #FFFFFF;
  font-family: futura-pt-book;
  font-size: 17px;
  padding: 15px 20px 30px 20px;

  height: 30vw;
}
.story-descr > a {
  color: #FFFFFF
}
.story-header-block {
  position: absolute;
  left: 6.85vw;
  top: 3.15vw;
  right: 3.89vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.story-close-button {
  width: 7.6vw;
  height: 7.6vw;
  background-image: url('../assets/media/stories/close-btn.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.story-header-progress-block {
  height: 20px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.story-header-progress-bg {
  background-color: rgba(255, 255, 255, .5);
  height: 1.85vw;
  border-radius: 15px;
  overflow: hidden;
}
.story-header-progress {
  background-color: #FFFFFF;
  height: 1.85vw;
  border-radius: 15px;
}
.story-header-progress-animation {
  animation-duration: 10s;
  animation-name: progress;
}
@keyframes progress {
  from { width: 0; }
  to { width: 100%; }
}
.story-video {
  object-fit: contain;
  width: 100%;
  border: 0;
}