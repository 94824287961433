.main-stories-block {
  background-image: url('../assets/media/main/poster.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: -1;
}
.main-video {
  width: 100%;
  z-index: 1;
}
.main-header-block {
  position: fixed;
  left: 25px;
  top: 25px;
  right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-avatar-block {
  width: 120px;
}
.main-avatar {
  width: 9.259vw;
  height: 9.259vw;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.main-logo {
  width: 40px;
  height: 40px;
  background-image: url('../assets/media/main/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.main-scores {
  width: 120px;
  color: #FFFFFF;
  text-align: right;
  font-family: futura-pt-light;
  font-size: 20px;
}
.main-indent-block {
  width: 100%;
  height: calc(100vh - 19.8vw);
}
.main-info-block {
  width: 100%;
  background-image: url('../assets/media/main/bg.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 57.1vw;
}
.main-carousel-block {
  overflow-x: auto;
  white-space: nowrap;
  overflow: -moz-scrollbars-none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  padding-right: 20px;
}
.main-carousel-block::-webkit-scrollbar { width: 0; }
.main-story-block {
  width: 90px;
  height: 130px;
  display: inline-block;
  margin-left: 20px;
  cursor: pointer;
}
.main-story-icon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.main-story-name {
  color: #000000;
  text-align: center;
  font-family: futura-pt-book;
  font-size: 17px;
  margin-top: 3px;
}
.main-scan-block {
  margin-top: 8vw;
  width: 100%;
  height: 178.1vw;
  background-image: url('../assets/media/main/scan-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.main-scan-header {
  padding-top: 1vw;
  padding-left: 16vw;
  text-transform: uppercase;
  font-size: 30px;
  color: #3D3933;
  letter-spacing: -1px;
  font-family: futura-pt-book;
}
.main-scan-header > span {
  font-size: 40px;
  color: #BC9458;
  font-family: Calypso;
  letter-spacing: -2px;
}
.main-scan-descr {
  padding-right: 10vw;
  text-align: right;
  font-size: 22px;
  line-height: 22px;
  color: #3D3933;
  font-family: futura-pt-light;
}
.main-scan-button-block {
  margin-top: 10vw;
  padding-right: 10vw;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.main-scan-button {
  width: 58vw;
  height: 18.6vw;
  background-image: url('../assets/media/main/scan-button.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  cursor: pointer;
}
.main-store-block {
  position: relative;
  margin-top: 4vw;
  width: 100%;
  height: 172.4vw;
  background-image: url('../assets/media/main/store-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.main-store-header {
  padding-left: 36vw;
  text-transform: uppercase;
  font-size: 26px;
  color: #3D3933;
  font-family: futura-pt-book;
}
.main-store-header > span {
  font-size: 38px;
  color: #BC9458;
  font-family: Calypso;
}
.main-store-descr {
  padding-left: 13.8vw;
  font-size: 22px;
  line-height: 22px;
  color: #3D3933;
  font-family: futura-pt-light;
}
.main-store-button-block {
  position: absolute;
  bottom: 21vw;
  padding-right: 7.6vw;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.main-store-button {
  width: 68.1vw;
  height: 12.26vw;
  background-image: url('../assets/media/main/store-button.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  cursor: pointer;
}
.main-game-block {
  width: 100%;
  padding-top: 86.296vw;
  padding-bottom: 5vw;
  background-image: url('../assets/media/main/game-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}
.main-game-header {
  padding-left: 13.88vw;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 38px;
  color: #3D3933;
  font-family: futura-pt-book;
}
.main-game-header > span {
  font-size: 40px;
  color: #BC9458;
  font-family: Calypso;
}
.main-game-button {
  width: 72.315vw;
  height: 12.22vw;
  background-image: url('../assets/media/main/game-button.png');
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin: 6vw auto;
}
.main-game-descr {
  padding-left: 13.88vw;
  padding-top: 3vw;
  font-family: futura-pt-light;
  color: #3D3933;
  line-height: 21px;
  font-size: 21px;
}
.main-tabbar-indent {
  width: 100%;
  height: 10vw;
}
.main-contest-block {
  width: 100%;
  background-image: url('../assets/media/main/contest-bg.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.main-contest-header {
  padding-top: 68vw;
  padding-right: 10.37vw;
  text-align: right;
  text-transform: uppercase;
  font-size: 30px;
  color: #3D3933;
  font-family: futura-pt-book;
}
.main-contest-header > span {
  font-size: 40px;
  color: #BC9458;
  font-family: Calypso;
  letter-spacing: -1px;
}
.main-contest-descr {
  text-align: right;
  padding-right: 11vw;
  padding-top: 3vw;
  font-family: futura-pt-light;
  color: #3D3933;
  line-height: 21px;
  font-size: 21px;
}
.main-contest-button {
  margin: 8vw auto;
  width: 79.26vw;
  height: 12.222vw;
  background-image: url('../assets/media/main/contest-button.png');
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}