.promo-block {
  position: relative;
  width: 100%;
  height: 432.9vw;
  background-image: url('../assets/media/promo/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.promo-header-block {
  position: relative;
  width: 100%;
  height: 235vw;
}
.promo-header {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-family: futura-pt-bold;
  text-align: center;
  color: #FFFFFF;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: 1px;
}
.promo-header > span {
  letter-spacing: 4px;
}
.promo-list-block {
  margin-top: 5vw;
  width: 100%;
  height: 60vw;
}
.promo-list-item-block {
  display: flex;
  padding-left: 26vw;
  padding-top: 5vw;
}
.promo-list-icon {
  width: 12vw;
  height: 12vw;
  background-repeat: no-repeat;
  background-size: contain;
}
.promo-list-text {
  color: #FFFFFF;
  font-size: 13px;
  line-height: 19px;
  padding-left: 4vw;
  font-family: futura-pt-bold;
}
.promo-icon-1 {
  background-image: url('../assets/media/promo/icon-1.png');
}
.promo-icon-2 {
  background-image: url('../assets/media/promo/icon-2.png');
}
.promo-icon-3 {
  background-image: url('../assets/media/promo/icon-3.png');
}
.promo-scan-block {
  position: relative;
  width: 100%;
  height: 53vw;
}
.promo-scan-text {
  color: #FFFFFF;
  font-family: futura-pt-bold;
  font-size: 22px;
  text-align: center;
  padding-top: 10px;
}
.promo-scan-text > span {
  text-decoration: underline;
}
.promo-scan-button-block {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}
.promo-scan-button {
  width: 88.7%;
  height: 16.1vw;
  background-image: url('../assets/media/promo/scan-button.png');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}
.promo-copyright-block {
  position: relative;
  width: 100%;
  height: 69vw;
}
.promo-copyright-1 {
  width: 100%;
  text-align: center;
  color: #000000;
  font-family: futura-pt-book;
  font-size: 14px;
  padding-top: 28px;
  line-height: 16px;
}
.promo-copyright-2 {
  width: 100%;
  text-align: center;
  color: #2D2A26;
  font-family: futura-pt-book;
  font-size: 13px;
  line-height: 15px;
  padding-top: 27px;
}
.promo-copyright-2 > a {
  color: #2D2A26;
}
.promo-copyright-button-block {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}