* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body {
  background-color: #FFFFFF;
}
div, p, span, h1, h2, h3, h4, img {
  -moz-user-select: -moz-none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
@font-face {
  font-family: Calypso;
  src: url('../assets/fonts/Calypso.ttf');
}
@font-face {
  font-family: futura-pt-bold;
  src: url('../assets/fonts/futura-pt-bold.ttf');
}
@font-face {
  font-family: futura-pt-book;
  src: url('../assets/fonts/futura-pt-book.ttf');
}
@font-face {
  font-family: futura-pt-light;
  src: url('../assets/fonts/futura-pt-light.ttf');
}
@font-face {
  font-family: Cormorant-Bold;
  src: url('../assets/fonts/Cormorant-Bold.ttf');
}
@font-face {
  font-family: FuturaPTBook;
  src: url('../assets/fonts/FuturaPTBook.otf');
}
@font-face {
  font-family: Raleway-ExtraBold;
  src: url('../assets/fonts/Raleway-ExtraBold.ttf');
}
@font-face {
  font-family: Raleway-Bold;
  src: url('../assets/fonts/Raleway-Bold.ttf');
}
@font-face {
  font-family: Raleway-SemiBold;
  src: url('../assets/fonts/Raleway-SemiBold.ttf');
}
@font-face {
  font-family: Raleway-Black;
  src: url('../assets/fonts/Raleway-Black.ttf');
}
@font-face {
  font-family: Raleway-Medium;
  src: url('../assets/fonts/Raleway-Medium.ttf');
}

#root {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}
textarea:focus {
  outline-width: 0;
}
input:focus {
  outline-width: 0;
}
#root::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.back-button {
  position: absolute;
  width: 8vw;
  height: 8vw;
  left: 4vw;
  top: 6vw;
  background-image: url('../assets/media/back-button.png');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  z-index: 1;
}
.header-block {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  padding-top: 6vw;
}
.header {
  width: 36.29%;
  height: 9.9vw;
  background-image: url('../assets/media/header.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.header-logo {
  width: 28.3%;
  height: 9.25vw;
  background-image: url('../assets/media/logo.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.blue-button {
  background-color: #245DA3;
  color: #FFFFFF;
  font-size: 18px;
  font-family: futura-pt-book;
  width: 88.8%;
  height: 12.9vw;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.gold-button {
  background-color: #BC9458;
  color: #FFFFFF;
  font-size: 18px;
  font-family: futura-pt-book;
  width: 88.8%;
  height: 12.9vw;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.white-button {
  color: #BC9458;
  font-size: 18px;
  font-family: futura-pt-book;
  width: 88.8%;
  height: 12.9vw;
  border-radius: 30px;
  border: 1px solid #BC9458;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}