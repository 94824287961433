.scan-metro-block {
  position: relative;
  width: 100%;
  height: 177.7vw;
  background-image: url('../assets/media/scan-metro/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.scan-metro-header {
  width: 100%;
  text-align: center;
  color: #2D2A26;
  font-family: futura-pt-bold;
  font-size: 24px;
  padding-top: 21vw;
}
.scan-metro-descr {
  width: 100%;
  text-align: center;
  color: #3D3933;
  font-family: futura-pt-book;
  font-size: 16px;
  line-height: 22px;
  padding-top: 1vw;
}
.scan-metro-descr > i {
  display: inline-block;
  width: 13.5vw;
  height: 4.5vw;
  background-image: url('../assets/media/scan-metro/metro.png');
  background-position: center -10%;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: middle
}
.scan-metro-stock {
  width: 100%;
  text-align: center;
  color: #2D2A26;
  font-family: futura-pt-bold;
  font-size: 17px;
  padding-top: 5.5vw;
}
.scan-metro-bottles {
  margin-top: 4vw;
  width: 100%;
  height: 68.4vw;
  background-image: url('../assets/media/scan-metro/bottles.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.scan-metro-buttons {
  margin-top: 7vw;
  width: 100%;
  height: 31vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}