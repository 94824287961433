.statuses-block {
  width: 100%;
  background-image: url('../assets/media/statuses/bottle.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
}
.statuses-title-block {
  width: 100%;
  height: 140vw;
  color: #FFFFFF;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: futura-pt-book;
  font-size: 36px;
  padding-left: 5.6vw;
  padding-bottom: 5vw;
}
.statuses-content-block {
  width: 100%;
  background-image: url('../assets/media/statuses/bg.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  border-radius: 30px 0 0 0;
}
.statuses-descr {
  margin: 0 auto;
  width: 88.8%;
  font-size: 16px;
  font-family: futura-pt-book;
  color: #3D3933;
  padding: 6.5vw 0;
}
.statuses-status {
  margin-bottom: 6vw;
  width: 100%;
  display: flex;
  justify-content: center;
}
.statuses-tabbar-indent {
  width: 100%;
  height: 35vw;
}